.me-block.me-Sidebar {
  min-height: 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content: center;
  @media #{$large-up} {flex-wrap: nowrap;}
  &[data-side='right'] {flex-direction: row-reverse;}
  &.nowrap {flex-wrap:nowrap;}
  &.wrap {flex-wrap:wrap;}
  &.no-wrap{
    @media #{$medium-only} {flex-wrap: nowrap;}
  }
  .me-Sidebar-Fixed, .me-Sidebar-Content {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  @media #{$medium-only} {
    .me-Sidebar-Fixed{min-height:0;}
  }
  @media #{$small-only} {
    .me-Sidebar-Fixed{min-height:0;}
  }
  .me-Sidebar-Fixed {
    flex-grow: 0;
    flex-shrink: 0;
  }
  .me-Sidebar-Content {
    flex-grow: 1;
    min-width: 0; /* fix for containing slick slider */
    width:100%;
  }
}
