hr {
  margin: 0;
}

.link-mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .me-block {
    opacity: 0;
  }
  &:hover .me-block {
    opacity: 1;
  }
}

.hide-element {
  display: none !important;
}

#filter {
  display: flex;
  justify-content: center;
  z-index: 99;
  position: relative;
  flex-wrap: wrap;
  .MES22 {
    margin: 5px;
  }
}

.contact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media #{$medium-up} {
    flex-direction: column;
    padding: 20px 30px 40px;
  }
  @media #{$small-only} {
    & > .me-Button {
      margin: 0 5px;
    }
  }
}

.back-to-artists {
  position: absolute;
  top: 35px;
  right: 20px;
  @media #{$medium-only} {
    right: 30px;
  }
}

.Person_contact-panel {
  position: sticky;
  top: 20px;
}

.image-mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.5s ease;
  &:hover {
    opacity: 1;
    transition: opacity 0.5s ease;
  }
  i {cursor: pointer;}
}

.search-panel > .row > .column {
  display: grid;
  grid-template-columns: 0 0 1fr auto;
  .me-SearchSolrFilterWord {
    display: flex;
    flex-direction: column;
    & > div {
      display: flex;
      flex: 1;
      input {
        flex: 1;
        height: auto !important;
        margin: 0;
      }
    }
  }
}

.me-Tab {
  @media #{$small-only} {
    .me-Tab-title {
      text-align: center;
      font-size: 18px !important;
    }
  }
  .me-Tab-arrow {
    z-index: 9;
  }
}

.social-media {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  a {
    font-size: 22px;
    border: 1px solid black;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
  }
}

.me-ProdFormQty, .checkout2Qty, .me-CartQty {
  input {
    width: 56px;
    height: 56px;
    margin: 0 10px 0 0;
    text-align: center;
  }
}
@media #{$small-only} {
  .checkout2Qty, .me-CartQty {
    input {
      width: 48px;
      height: 48px;
    }
  }
}

@mixin cartFields {
  border-radius: 5px;
  padding: 5px 15px;
  height: 53px;
  &:focus {
    outline: none;
    box-shadow: none;
    border-color: #2a9dcc;
    border-width: 2px;
  }
}


.me-CartAccount, .me-CartAddress,.me-CartPromoCode,
.me-PayStripeCardName, .me-PayStripeCardNo,
.me-PayStripeCardCVC{
  input {
    @include cartFields;
  }
}
.me-CartPromoCode input {margin: 0;}
.me-CartAddress, .me-PayStripeCardExpMo, .me-PayStripeCardExpYr{
  select {
    @include cartFields;
  }
}

@media #{$small-only} {
  .cart-review {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
    &.active{
      transition: max-height 0.5s ease;
      max-height: 10000px;
    }
  }
  .cart-view-trigger {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 30px;
    grid-gap: 0 10px;
    align-items: center;
  }
}
