//Admin Only

@mixin wrap-border($color) {
  .me-owrap {
    border-color: $color;
    .me-block-head {
      background-color: $color;
    }
  }
  .drop-target {
    border-color: $color;
  }
}

@mixin input_field_reset() {
  box-shadow: none !important;
  border: 0 !important;
  background-color: transparent !important;
  color: inherit !important;
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;
  height: auto !important;
  margin: 0 !important;
  padding: 0 !important;
  text-align: inherit !important;
  text-transform: inherit !important;
}

@mixin placeholder($color) {
  ::placeholder {
    color: $color;
  }
}

@mixin slider-triangle-arrows(
  $outterArrowSize: 50px,
  $outterArrorColor: rgba(123, 191, 242, 0.8),
  $innerArrowSize: 20px,
  $innerArrowColor: #FFFFFF
) {
  .slider-control-wrap {
    .slider-arrow-left {
      left: 0;
      position: absolute;
      cursor: pointer;
      width: 0;
      height: 0;
      border-top: $outterArrowSize solid transparent;
      border-left: $outterArrowSize solid $outterArrorColor;
      border-bottom: $outterArrowSize solid transparent;
      z-index: 99;
      top: 50%;
      margin-top: -$outterArrowSize;
      i {
        left: -40px;
      }
    }
    .slider-arrow-right {
      right: 0;
      position: absolute;
      cursor: pointer;
      width: 0;
      height: 0;
      border-top: $outterArrowSize solid transparent;
      border-right: $outterArrowSize solid $outterArrorColor;
      border-bottom: $outterArrowSize solid transparent;
      z-index: 99;
      top: 50%;
      margin-top: -$outterArrowSize;
      i {
        right: -40px;
      }
    }
    i {
      position: absolute;
      font-size: $innerArrowSize;
      top: 50%;
      margin-top: -$innerArrowSize / 2;
      color: $innerArrowColor;
    }
  }
}

@mixin accordion(
  $titleBg               : $MEAccordionTitleBg,
  $titlePadding          : $MEAccordionTitlePad,
  $titleBorder           : $MEAccordionTitleBorder,
  $titleRadius           : $MEAccordionTitleRadius,
  $titleFontSize         : $MEAccordionTitleFontSize,
  $titleFontColor        : $MEAccordionTitleColor,
  $titleBorderBottom     : $MEAccordionTitleBorderBottom,

  $iconSize              : $MEAccordionIconSize,
  $iconColor             : $MEAccordionIconColor,
  $iconMargin            : $MEAccordionIconMargin,
  $bodyBorder            : $MEAccordionBodyBorder,
  $bodyPadding           : $MEAccordionBodyPad,
  $MEAccordionBodyBgColor: $MEAccordionBodyBgColor
) {
  .accordion {
    display: block;
    .accordion-head {
      cursor: pointer;
      border-radius: $titleRadius;
      background: $titleBg;

      @if $titleBorderBottom {
        border-bottom: $titleBorder;
      } @else {
        border: $titleBorder;
      }
      padding: $titlePadding;
      i, h4 {
        display: inline-block;
        vertical-align: middle;
      }
      i {
        color: $iconColor;
        font-size: $iconSize;
        margin-right: $iconMargin;
      }
      h4 {
        width: 89%;
        color: $titleFontColor;
        font-size: $titleFontSize;
        margin: 0;
        line-height: $titleFontSize;
      }
      input {
        max-width: 89%;
        display: inline-block;
        vertical-align: middle;
        @include input_field_reset();
        color: $titleFontColor;
        font-size: $titleFontSize;
        margin: 0;
        line-height: $titleFontSize;
      }
    }
    .accordion-body {
      border: $bodyBorder;
      border-top: 0;
      margin-top: -1px;
      padding: $bodyPadding;
      display: none;
      background-color: $MEAccordionBodyBgColor;
    }
  }
}

@mixin tab(// Tab style
  $METabBgType                          : $METabBgType, // image OR color

  $METabBgImage                         : $METabBgImage,
  $METabActiveBgImage                   : $METabActiveBgImage,

  $METabBgColor                         : $METabBgColor,
  $METabActiveBgColor                   : $METabActiveBgColor,

  $METabFontSize                        : $METabFontSize,
  $METabFontColor                       : $METabFontColor,
  $METabFontFamily                      : $METabFontFamily,
  $METabActiveFontColor                 : $METabActiveFontColor,

  $METabPadding                         : $METabPadding,
  $METabSpacing                         : $METabSpacing,

  $METabBorderWidth                     : $METabBorderWidth,
  $METabBorderColor                     : $METabBorderColor,
  $METabRadius                          : $METabRadius,

  $METabBottomTabSpace                  : $METabBottomTabSpace,
  $METabBottomTabPosition               : $METabBottomTabPosition,

  // Content Style
  $METabContentPadding                  : $METabContentPadding,
  $METabContentBorderWidth              : $METabContentBorderWidth,
  $METabContentBorderColor              : $METabContentBorderColor,
  $METabContentBgColor                  : $METabContentBgColor,
  // tab down arrow
  $METabArrow                           : $METabArrow,
  $METabArrowWidth                      : $METabArrowWidth,
  // tab separator
  $METabSepWidth                        : $METabSepWidth,
  $METabSepStyle                        : $METabSepStyle,
  $METabSepColor                        : $METabSepColor
) {
  .me-Tab > .me-block.me-Tab, .me-Tab.me-block > .tab {
    font-size: 0;
    margin-top: 20px;
    position: relative;
    &:after {
      visibility: hidden;
      display: block;
      font-size: 0;
      content: " ";
      clear: both;
      height: 0;
    }
    & > ul {
      list-style: outside none none;
      padding: 0;
      margin: 0;
      & > li {
        color: $METabFontColor;
        cursor: pointer;
        font-size: $METabFontSize;
        padding: $METabPadding;
        margin: 0 $METabSpacing;
        border: $METabBorderWidth solid $METabBorderColor;
        font-family: $tabFontFamily;

        // border-left: $tabBorderWidth solid $tabBorderColor;
        user-select: none;
        & > * {
          display: inline-block;
          vertical-align: middle;
        }
        & > i {
          width: auto;
          max-width: 40px;
        }
        & > span {
          border-right: $METabSepWidth $METabSepStyle $METabSepColor;
          width: 100%;
        }
        input {
          @include input_field_reset();
          color: $METabFontColor;
          font-size: $METabFontSize;
          font-family: $METabFontFamily;
        }
        @if $METabBgType == image {
          background-image: $METabBgImage;
        } @else {
          background-color: $METabBgColor;
        }
        & input {
          color: $METabFontColor;
        }
        &:last-child {
          margin-right: 0;
          & > span {
            border-right: 0;
          }
        }
        &.tab-active {
          @if $METabBgType == image {
            background-image: $METabActiveBgImage;
          } @else {
            background-color: $METabActiveBgColor;
          }

          h4 {
            font-weight: 700;
            color: $METabActiveFontColor;
          }
          input, span {
            font-weight: 700;
            color: $METabActiveFontColor;
          }
        }
      }
    }
    .me-Tab-title {
      color: $METabFontColor;
      cursor: pointer;
      font-size: $METabFontSize;
      padding: $METabPadding;
      margin: 0 $METabSpacing;
      border: $METabBorderWidth solid $METabBorderColor;
      font-family: $tabFontFamily;
      border-bottom: 1px dashed #FFFFFF;
      display: block;
      user-select: none; /* Chrome all / Safari all */
      @if $METabBgType == image {
        background-image: $METabActiveBgImage;
      } @else {
        background-color: $METabActiveBgColor;
      }
      input {
        @include input_field_reset();
      }
      i {
        font-size: 16px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
      }
    }

    .me-Tab-content {
      padding: $METabContentPadding;
      border: $METabContentBorderWidth solid $METabContentBorderColor;
      border-top: 0;
      font-size: $MEDefaultFontSize;
      line-height: $MEDefaultLineHeight;
      background-color: $METabContentBgColor;
      @media screen and (max-width: 800px) {
        border-color: $METabContentBorderColor;
        border-top: 0;
      }
    }
    &[data-direction="top"] {
      & > ul {
        & > li {
          text-align: center;
          border-top-left-radius: $METabRadius;
          border-top-right-radius: $METabRadius;
          border-bottom: 0;
          display: inline-block;
          margin: 0;
          font-size: $METabFontSize;
          font-family: $METabFontFamily;
          color: $METabFontColor;
          input {
            text-align: center;
            width: 100%;
            @include input_field_reset();
            font-size: $METabFontSize;
            font-family: $METabFontFamily;
            color: $METabFontColor;
          }
        }
      }
    }
    &[data-direction="bottom"] {
      padding-bottom: $METabBottomTabSpace;
      & > ul {
        position: absolute;
        bottom: $METabBottomTabPosition;
        left: 0;
        right: 0;
        & > li {
          text-align: center;
          border-bottom-left-radius: $METabRadius;
          border-bottom-right-radius: $METabRadius;
          border-top: 0;
          margin: 0;
          display: inline-block;
          input {
            text-align: center;
            width: 100%;
          }
        }
      }
    }
    &[data-direction="left"] {
      & > ul {
        float: left;
        width: 20%;
        & > li {
          text-align: center;
          width: 100%;
          input {
            text-align: center;
            width: 100%;
          }
          border-right: 0;
          border-top-left-radius: $METabRadius;
          border-bottom-left-radius: $METabRadius;
        }
      }
      .me-Tab-block {
        float: left;
        width: 80%;
      }
    }
    &[data-direction="right"] {
      & > ul {
        float: right;
        width: 20%;
        & > li {
          text-align: center;
          width: 100%;
          input {
            text-align: center;
            width: 100%;
          }
          border-left: 0;
          border-top-right-radius: $METabRadius;
          border-bottom-right-radius: $METabRadius;
        }
      }
      .me-Tab-block {
        float: right;
        width: 80%;
      }
    }
    &[data-icon="hide"] {
      & > ul > li i {
        display: none;
      }
    }
    @if $tabArrow == true {
      li {
        position: relative;
        .me-Tab-arrow {
          width: 0;
          height: 0;
          border-left: $METabArrowWidth solid transparent;
          border-right: $METabArrowWidth solid transparent;
          border-top: $METabArrowWidth solid $METabActiveBgColor;
          position: absolute;
          left: 50%;
          margin-left: -$METabArrowWidth;
          bottom: -$METabArrowWidth;
          display: none;
        }
        &.tab-active {
          .me-Tab-arrow {
            display: block;
          }
        }
      }
    }
  }
}

@mixin faq(
  $MEFaqHeadBg               : $MEFaqHeadBg, // image or color
  $MEFaqHeadPadding          : $MEFaqHeadPadding,
  $MEFaqHeadBorderBottom     : $MEFaqHeadBorderBottom,
  $MEFaqHeadBorder           : $MEFaqHeadBorder,
  $MEFaqHeadFontSize         : $MEFaqHeadFontSize,
  $MEFaqHeadFontColor        : $MEFaqHeadFontColor,
  $MEFaqHeadRadius           : $MEFaqHeadRadius,
  $MEFaqBodyPadding          : $MEFaqBodyPadding,
  $MEFaqBodyBorder           : $MEFaqBodyBorder,
  $MEFaqIconDirection        : $MEFaqIconDirection,
  $MEFaqIconFontSize         : $MEFaqIconFontSize,
  $MEFaqIconFontColor        : $MEFaqIconFontColor,
  $MEFaqPad                  : $MEFaqPad,
  $MEFaqBodyBgColor          : $MEFaqBodyBgColor,
  $MEFaqHeadLineHeight       : $MEFaqHeadLineHeight
) {
  ul.faq {
    list-style: outside none none;
    margin: 0;
    padding: 0;
    font-size: inherit;
    li.faq-body + li.faq-head {
      margin-top: $MEFaqPad;
    }
  }
  .faq-head {
    background: $MEFaqHeadBg;
    cursor: pointer;
    padding: $MEFaqHeadPadding;
    display: table;
    width: 100%;
    max-width: 100%;
    @if $MEFaqHeadBorderBottom {
      border-bottom: $MEFaqHeadBorder;
    } @else {
      border: $MEFaqHeadBorder;
    }
    border-radius: $MEFaqHeadRadius;
    h4 {
      display: table-cell;
      vertical-align: middle;
      font-size: $MEFaqHeadFontSize;
      color: $MEFaqHeadFontColor;
      margin: 0;
      line-height: $MEFaqHeadLineHeight;
      width: 100%;
    }
    i {
      display: table-cell;
      font-size: $MEFaqIconFontSize;
      color: $MEFaqIconFontColor;
      @if $MEFaqIconDirection == right {
        float: right;
        margin-left: $MEFaqIconSpace;
      } @else {
        margin-right: $MEFaqIconSpace;
        display: inline-block;
        vertical-align: middle;
      }
    }
    & > div {
      display: inline-block;
      vertical-align: middle;
      width: auto;
    }
    & > span, & > i {
      display: inline-block;
      vertical-align: middle;
    }
    & > span {
      max-width: 89%;
    }
  }
  .faq-body {
    background-color: $MEFaqBodyBgColor;
    border: $MEFaqBodyBorder;
    border-top: 0;
    color: $MEFaqBodyFontColor;
    padding: $MEFaqBodyPadding;
    margin-top: -1px;
    p {
      padding: 0;
      font-size: $MEFaqBodyFontSize;
    }
    p + p {
      padding-top: $MEDefaultParaPad;
    }
  }
}

@mixin menu(
  $MEMenuFontSize        : $MEMenuFontSize,
  $MEMenuItemBgColor     : $MEMenuBg,
  $MEMenuFontColor       : $MEMenuFg,
  $MEMenuHoverFontColor  : $MEMenuFgHover,
  $MEMenuItemHoverBgColor: $MEMenuBgHover,
  $MEMenuPadding         : $MEMenuItemPad,
  $MEMenuSep             : $MEMenuSepBorder,
  $MEMenuMargin          : $MEMenuItemMargin
) {
  ul.me-Menu {
    padding: 0;
    margin: 0;
    list-style: none;
    & > li {
      border-bottom: $MEMenuSepBorder;
      margin: $MEMenuItemMargin;
      background: $MEMenuBg;
      display: block;
      &:before {
        display: inline-block;
        vertical-align: middle;
        color: $MEMenuFontColor;
        margin-left: 10px;
        margin-right: 5px;
      }

      & > :last-child {
        border-bottom: none;
      }
      a {
        font-size: $MEMenuFontSize;
        color: $MEMenuFontColor;
        display: inline-block;
        max-width: 85%;
        vertical-align: middle;
        padding: 10px 0;
      }
      &:hover {
        color: $MEMenuFgHover;
        background: $MEMenuBgHover;
      }
    }
    &[data-icon] {
      & > li > a:before {
        margin-right: 5px;
        margin-left: -10px;
      }
    }
  }
}

@mixin button($textColor, $bgColor, $hoverTextColor, $hoverbgColor) {
  background-color: $bgColor;
  color: $textColor;
  cursor: pointer;
  &:hover {
    background-color: $hoverbgColor;
    color: $hoverTextColor;
  }
}

@mixin border-radius($topLeft, $topRight, $bottomLeft, $bottomRight) {
  border-top-left-radius: $topLeft;
  border-top-right-radius: $topRight;
  border-bottom-left-radius: $bottomLeft;
  border-bottom-right-radius: $bottomRight;
}

// links
@mixin link($linkColor, $decoration, $hoverColor, $hoverDecoration) {
  color: $linkColor;
  text-decoration: $decoration;
  &:hover {
    color: $hoverColor;
    text-decoration: $hoverDecoration;
  }
}

// Formatting for Block - Heading
@mixin h($level, $size) {
  #admin-edit .me-block.me-Heading {
    h#{$level} {
      font-size: $size;
      input[type="text"] {
        @include input_field_reset();
        width: 100%;
      }
    }
  }
}

// Formatting for Block - Masonry
@mixin masonry(
  $MEMasonryColumns                 : $MEMasonryColumns,
  $MEMasonryOutterPad               : $MEMasonryOutterPad,
  $MEMasonryColumnPad               : $MEMasonryColumnPad,
  $MEMasonryItemPad                 : $MEMasonryItemPad,
  $MEMasonryItmeBottomSpace         : $MEMasonryItemBottomSpace


) {
  .masonry {
    margin: $MEMasonryOutterPad;
    padding: 0;
    column-gap: $MEMasonryColumnPad;
    column-count: $MEMasonryColumns;
  }

  .item {
    display: inline-block;
    background: #fff;
    padding: $MEMasonryItemPad;
    margin-bottom: $MEMasonryItmeBottomSpace;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 2px 2px 4px 0 #ccc;
  }
}

@mixin text-select-colour($text-select-colour) {
  ::selection {
    background: $text-select-colour;
  }
}

@mixin slider-mask() {
  .me-slider-mask.me-block.me-Slider {
    .item {
      position: relative;
      & > .me-PanelCol,
      & > .me-Slide-content > .me-iwrap > .me-PanelCol{
        position: absolute;
        top: 0;
        bottom: 0;
      }
    }
  }

  .me-design {
    .me-slider-mask.me-block.me-Slider {
      .item {
        position: relative;
        & > .me-PanelCol,
        & > .me-Slide-content > .me-iwrap > .me-PanelCol{
          position: relative;
          top: auto;
          bottom: auto;
        }
      }
    }
  }
}


// functions
@function toRgb($colour, $opacity: null) {
  $o: 0;
  @if ($opacity) {
    $o: $opacity;
  } @else {
    $o: 1;
  }
  @return rgba(red($colour), green($colour), blue($colour), $opacity);
}
