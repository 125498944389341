ul.tick {
  list-style: none;
  & > li:before {
    content: '\f00c';
    margin-right: 5px;
    font-family: FontAwesome;
  }
}

.flex.row,
.bldform .flex.row {
  display: flex;
  justify-content: space-between;
  & > .column {
    flex: 1;
  }
}

.login-button-wrap > .row > .column,
.login-button-wrap > .column {
  display: flex!important;
  flex-direction: row !important;
  @media #{$large-up} {
    display: grid !important;
    grid-template-columns: auto 60px;
    grid-gap: 0 15px;
  }
  .me-block {
    width: auto;
  }
}

.me-design {
  .login-button-wrap > .column {
    display: flex !important;
    .me-block {
      width: 100%;
    }
  }
}
